import React from 'react';
import { IconAppStore, IconGooglePlay } from '../icons/icons';
import { Urls } from '../../const';

const { APP_STORE, PLAY_MARKET } = Urls;

const Apps = ({ className }) => {
	return (
		<div className={`${className} apps`}>
			<span className="apps__title">Скачайте приложение</span>
			<a href={APP_STORE} className="apps__link">
				<IconAppStore />
			</a>
			<a href={PLAY_MARKET} className="apps__link">
				<IconGooglePlay />
			</a>
		</div>
	);
};

export default Apps;
