import React from 'react';
import { Screens } from '../../../const';

const ServiceItem = ({ setScreen, serviceCode, serviceName, serviceText }) => {
	const clickHandler = (evt) => {
		evt.preventDefault();
		setScreen((screen) => {
			return {
				...screen,
				type: Screens.SERVICE_DETAILS,
				title: serviceName,
				textArr: serviceText,
			};
		});
	};
	return (
		<p className="check-result__services-item">
			<span className="check-result__service-code">{`Код мероприятия: ${serviceCode}`}</span>
			<b className="check-result__service-name">{serviceName}</b>
			{!!serviceText && (
				// eslint-disable-next-line
				<a className="check-result__service-link" onClick={clickHandler}>
					Читать подробнее
				</a>
			)}
		</p>
	);
};

export default ServiceItem;
