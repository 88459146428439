export const Urls = {
  VIN: 'https://collection.audi.ru/api/airbags/check.php',
  DEALERS: 'https://www.audi.ru/ru/web/ru/dealer-search.html',
  SERVICE: 'https://services.audi.ru/forms/service/',
  APP_STORE: 'https://apps.apple.com/ru/app/audi-service/id664580565',
  PLAY_MARKET: 'https://play.google.com/store/apps/details?id=ru.audi.service&hl=ru',
}

export const ApiUrls = {
  DEV: 'https://api.recall-test.k8s-stage.vwgroup.dev',
  PROD: 'https://recall.vwgroup.ru'
};

export const Screens = {
  FORM: 'form',
  REQUIRED: 'required',
  NOT_REQUIRED: 'notRequired',
  SERVICE_DETAILS: 'serviceDetails',
};

export const PUBLIC_KEY = '6Ld_3qgeAAAAAKovl-mS825n8VynKlonYm6s8KGa';