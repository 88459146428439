import React, { useState } from 'react';
import EventForm from '../event-form/event-form';
import ServiceRequired from '../check-result/service-required/service-required';
import ServiceNotRequired from '../check-result/service-not-required/service-not-required';
import ServiceDetails from '../service-details/service-details';
import { Screens } from '../../const';

const ServiceCheck = () => {
	const [screen, setScreen] = useState({
		type: '',
		data: [],
		title: '',
		textArr: [],
	});

	const getScreen = () => {
		switch (screen.type) {
			case Screens.REQUIRED:
				return <ServiceRequired screen={screen} setScreen={setScreen} />;
			case Screens.NOT_REQUIRED:
				return <ServiceNotRequired setScreen={setScreen} />;
			case Screens.SERVICE_DETAILS:
				return <ServiceDetails screen={screen} setScreen={setScreen} />;
			default:
				return <EventForm setScreen={setScreen} />;
		}
	};

	return (
		<div className="container service-check">
			{getScreen()}
			<p className="service-check__disclaimer">
				* В&nbsp;настоящей базе данных проверяются только VIN-номера
				автомобилей, официально поставленных в&nbsp;Россию. Если Ваш автомобиль
				произведен для других рынков (США, Германия и&nbsp;т.д.),
				мы&nbsp;рекомендуем обратиться к&nbsp;Вашему дилеру для проверки Вашего
				автомобиля на&nbsp;предмет действия данного сервисного мероприятия.
			</p>
		</div>
	);
};

export default ServiceCheck;
