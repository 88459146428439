import React from 'react';
import { CheckIconWarning } from '../../icons/icons';
import { Urls } from '../../../const';
import ServiceItem from '../service-item/service-item';

const { SERVICE } = Urls;

const ServiceRequired = ({ screen, setScreen }) => {
	const { data: serviceItems } = screen;
	const servicesExtraClassname =
		serviceItems.length === 1
			? 'check-result__services--single'
			: serviceItems.length === 2
			? 'check-result__services--double'
			: '';
	return (
		<div className="check-result">
			<CheckIconWarning className={'check-result__icon'} />
			<h2 className="check-result__title">
				Требуется проведение сервисных мероприятий
			</h2>
			<div className={`check-result__services ${servicesExtraClassname}`}>
				{serviceItems.map((item, index) => (
					<ServiceItem
						key={index + item.code}
						serviceCode={item.code}
						serviceName={item.title}
						serviceText={item.text}
						setScreen={setScreen}
					/>
				))}
			</div>
			<div className="check-result__buttons">
				<a href={SERVICE} className="check-result__btn button" target="_parent">
					Записаться на&nbsp;сервис
				</a>
			</div>
		</div>
	);
};

export default ServiceRequired;
