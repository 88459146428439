import React from 'react';
import { Screens } from '../../../const';
import { CheckIconSuccess } from '../../icons/icons';
import Apps from '../../apps/apps';

const ServiceNotRequired = ({ setScreen }) => {
	return (
		<div className="check-result">
			<CheckIconSuccess className={'check-result__icon'} />
			<h2 className="check-result__title">
				Не требуется проведение сервисного мероприятия
			</h2>
			<p className="check-result__text">
				На&nbsp;Ваш автомобиль на&nbsp;данный момент нет открытых сервисных
				мероприятий, требующих незамедлительного визита к&nbsp;официальному
				дилеру Ауди, либо они были проведены ранее. Установите мобильное
				приложение Audi Service, чтобы отслеживать актуальность информации
				в&nbsp;режиме онлайн.
			</p>
			<Apps className="check-result__apps" />
			<div className="check-result__buttons">
				<button
					className="check-result__btn button"
					onClick={() =>
						setScreen((screen) => {
							return { ...screen, type: Screens.FORM };
						})
					}
				>
					Проверить другой VIN
				</button>
			</div>
		</div>
	);
};

export default ServiceNotRequired;
