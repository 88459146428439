import React, { useState } from 'react';
import axios from 'axios';
import { reCaptchaExecute } from 'recaptcha-v3-react-function-async';
import { Screens, ApiUrls, PUBLIC_KEY } from '../../const';
import { Loader } from '../icons/icons';

const VIN_LENGTH = 17;
const { PROD } = ApiUrls;
const url = PROD + '/service-campaigns';


const EventForm = ({ setScreen }) => {
	const [formData, setFormData] = useState({
		vinValue: '',
		vinError: 'initial',
		formValid: false,
		isDataLoading: false,
		isVinBlured: false,
	});

	const [transition, setTransition] = useState(false);

	const blurHandler = ({ target }) => {
		setFormData((formData) => {
			return { ...formData, isVinBlured: true };
		});
		if (formData.vinError === 'initial') {
			setFormData((formData) => {
				return { ...formData, vinError: 'поле должно быть заполнено' };
			});
		}
		if (target.value) {
			setTransition(!!target.value);
		}
	};

	const focusHandler = () => {
		setTransition(true);
	};

	const changeHandler = ({ target }) => {
		const error = target.value
			? target.value.trim().length === VIN_LENGTH
				? ''
				: `длина VIN должна быть ${VIN_LENGTH} символов`
			: 'поле должно быть заполнено';

		setFormData((formData) => {
			return {
				...formData,
				vinError: error,
				formValid: error === '',
				vinValue: target.value.trim(),
			};
		});
	};

	const submitHandler = async (evt) => {
		evt.preventDefault();
		setFormData((formData) => {
			return { ...formData, isDataLoading: true };
		});
		let gtoken = await reCaptchaExecute(PUBLIC_KEY, 'auth');

		if (formData.formValid) {
			const vin = formData.vinValue;
			let payload = {
				vin: vin,
				'available-to-users': 1,
				'g-recaptcha-verify': gtoken,
			};
			const params = new URLSearchParams(payload);
			try {
				const { data } = await axios.get(`${url}?${params}`);
				const { data: resData, meta } = data;
				if (resData[vin]) {
					setFormData((formData) => {
						return { ...formData, isDataLoading: false, vinValue: '' };
					});
					if (resData[vin]['items'].length > 0) {
						const data = resData[vin]['items'].map(({ code, name }) => ({ code, title: name }));
						setScreen((screen) => {
							return {
								...screen,
								type:
									data && data.length
										? Screens.REQUIRED
										: Screens.NOT_REQUIRED,
								data,
							};
						});
					} else {
						setScreen((screen) => {
							return { ...screen, type: Screens.NOT_REQUIRED };
						});
					}
				} else if (meta['user_error_message']) {
					setFormData((formData) => {
						return {
							...formData,
							vinError: 'Что-то пошло не так, попробуйте позже',
							isDataLoading: false,
							formValid: false,
						};
					});
				}
			} catch {
				console.log('here2');
				setFormData((formData) => {
					return {
						...formData,
						vinError: 'Что-то пошло не так, попробуйте позже',
						isDataLoading: false,
						formValid: false,
					};
				});
			}
		}
	};

	return (
		<form action="#" className="event-form" onSubmit={submitHandler}>
			<div className="event-form__field">
				<input
					className={`event-form__input ${
						formData.vinError && formData.vinError !== 'initial'
							? 'invalid'
							: ''
					}`}
					value={formData.vinValue}
					maxLength={VIN_LENGTH}
					onFocus={focusHandler}
					onBlur={blurHandler}
					onChange={(evt) => changeHandler(evt)}
					type="text"
					name="VIN"
					id="vin"
				/>
				<label
					className={`event-form__label ${
						transition ? 'event-form__label--transitioned' : ''
					}`}
					htmlFor="user-email"
				>
					VIN-номер*
				</label>
				{formData.isVinBlured && formData.vinError && (
					<p className="event-form__error">{formData.vinError}</p>
				)}
			</div>
			<div className="event-form__submit">
				<button
					disabled={
						formData.isDataLoading ? true : formData.formValid ? false : true
					}
					className="event-form__btn button"
					type="submit"
				>
					{formData.isDataLoading ? <Loader /> : 'Проверить автомобиль'}
				</button>
			</div>
		</form>
	);
};

export default EventForm;
