import React from 'react';
import { IconClose } from '../icons/icons';
import { Screens } from '../../const';

const ServiceDetails = ({ screen, setScreen }) => {
	const { title, textArr } = screen;
	const clickHandler = () => {
		setScreen((screen) => {
			return { ...screen, type: Screens.REQUIRED };
		});
	};
	return (
		<section className="service-details">
			<button className="service-details__close" onClick={clickHandler}>
				<IconClose />
			</button>
			<h2 className="service-details__title">{title}</h2>
			{textArr.map((paragraph, index) => (
				<p className="service-details__text" key={index}>
					{paragraph}
				</p>
			))}
			<button className="service-details__button button" onClick={clickHandler}>
				Понятно
			</button>
		</section>
	);
};

export default ServiceDetails;
